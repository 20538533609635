<template>
  <validation-observer
    slim
    ref="productCategoryObserver"
  >
    <b-row>
      <b-col cols="12">
        <label class="field-name-class">
          {{ $t('views.shop.form.category-name') }}
        </label>
      </b-col>
      <b-col cols="12">
        <d-text-field
          v-model="name"
          :rules="validation.name"
          class-name="background-light-blue-inputs"
          size="sm"
          error-text="validation.text-input-three-characters"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <label class="field-name-class">
          {{ $t('views.shop.form.description') }}
        </label>
      </b-col>
      <b-col cols="12">
        <b-textarea
          v-model="description"
          rows="5"
          no-resize
          class="background-light-blue-inputs"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col align="right">
        <d-button
          :text="productCategory.id ? 'general.actions.edit' : 'general.actions.add'"
          class="d-btn-sm font-text-title d-btn btn d-btn-primary-new font-text-title"
          @on:button-click="submit"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import ProductCategory from "@/classes/doinsport/ProductCategory";
import {postProductCategory, putProductCategory} from "@api/doinsport/services/products/products.api";


export default {
  data: () => ({
    name: null,
    description: null,
    validation: require('@validation/entities/doinsport/ProductCategory.json'),
  }),
  props: {
    productCategory: {
      type: Object,
      default: new ProductCategory()
    },
  },
  methods: {
    init() {
      if (this.productCategory.id) {
        this.name = this.productCategory.name;
        this.description = this.productCategory.description;
      } else {
        this.name = null;
        this.description = null;
      }
    },
    submit() {
      try {
        this.$refs.productCategoryObserver.validate().then((valid) => {
          if (valid) {
            this.productCategory.name = this.name;
            this.productCategory.description = this.description;
            this.createOrUpdateProductCategory()
              .then((response) => {
                this.$emit('on:product-category-create');
              })
            ;
          }
        });
      } catch (e) {
      }
    },
    createOrUpdateProductCategory () {
      return this.productCategory.id ? putProductCategory(this.productCategory) : postProductCategory(this.productCategory);
    }
  },
  beforeMount() {
    this.$store.dispatch('validator/set', this.validation);
  },
}
</script>
