<template>
  <div class="editor-wrapper">
    <vue-editor
      v-model="editorContent"
      :style="cssVars"
    />
  </div>
</template>

<script>
import {VueEditor} from 'vue2-editor';

export default {
  data: () => ({
    src: '',
    content: '',
    onDeep: true,
  }),
  props: {
    defaultContent: {
      type: String,
      default: this,
    },
    customHeight: {
      type: String,
      default: '540px',
    },
  },
  computed: {
    cssVars() {
      if (this.disabled) {
        return {
          '--minHeight': this.customHeight,
          '--display': "none",
        }
      }
      return {
        '--minHeight': this.customHeight,
        '--display': "block",
      }
    },
    editorContent: {
      get() {
        return this.content
      },
      set(updatedContent) {
        this.$emit('on:editor-content:update', updatedContent)
      }
    },
  },
  components: {
    VueEditor
  },
  created() {
    this.content = this.defaultContent;
  }
};
</script>
<style lang="scss" src="@lazy/_editor.scss" scoped/>

