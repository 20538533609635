<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    size="lg"
    header-class="my-second-class"
    :body-bg-variant="'gray-lighten'"
  >
    <b-row class="pt-1 pb-1">
      <b-col align="left" class="popup-title">
        {{ productCategory.id ? $t('views.shop.form.edit-category') : $t('views.shop.form.create-category') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3"/>
    <create-update-product-category-form
      :product-category="productCategory"
      @on:product-category-create="$bvModal.hide(modalId);$emit('on:product-category-created')"
      ref="form"
    />
  </b-modal>
</template>
<script>
import CreateUpdateProductCategoryForm from "@form/shop/CreateUpdateProductCategoryForm";
import ProductCategory from "@/classes/doinsport/ProductCategory";

const MODAL_ID = 'PRODUCT_CATEGORY_MODAL_ID';

export default {
  components: {
    CreateUpdateProductCategoryForm
  },
  props: {
    modalId: {
      type: String,
      default: MODAL_ID,
    },
    productCategory: {
      type: Object,
      default: () => new ProductCategory(),
    },
  },
  methods: {
    show() {
      this.$bvModal.show(this.modalId);
      this.$nextTick(() => {
        this.$refs.form.init();
      })
    }
  }
}
</script>
<style lang="scss" src="@lazy/_modal.scss" scoped/>
