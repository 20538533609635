import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

const URL = '/clubs/payment-token-packs'

export const getCreditPacks = (itemsPerPage = 500, page = 1, search = '', enabled) => {
  let endPoint = URL + '?club.id=' + clubId
    + '&itemsPerPage='
    + itemsPerPage
    + '&page=' + page
    + '&search=' + search
  ;

  if ("undefined" !== typeof enabled && !enabled) {
    endPoint += '&enabled=false';
  }

  if ("undefined" !== typeof enabled && enabled) {
    endPoint += '&enabled=true';
  }

  if ("undefined" === typeof enabled) {
    endPoint += '&enabled=true';
  }

  return _get(endPoint);
}


export const getCreditsPack = (id) => _get(URL + '/' + id);

export const postCreditsPack = (pack) => _post(URL, pack);

export const putCreditsPack = (pack) => _put(URL + '/' + pack.id, pack);

export const deleteCreditsPack = (id) => _delete(URL + '/' + id);
