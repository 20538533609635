import { hydrate } from "@/utils/form";

export default class ProductCategory {
  id = null;
  name =	null;
  description = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize (object) {
    hydrate(this, object);
  }
}
