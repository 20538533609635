<template>
  <validation-observer
    slim
    ref="productObserver"
  >
    <b-card>
      <b-row class="mb-2">
        <b-col cols="3">
          <label class="field-name-class">
           {{ $t('views.shop.form.product-type') }}
          </label>
          <d-select-option
            v-model="product.type"
            :options="typeProducts"
            :disabled="product.id"
            :rules="validation.type"
            class-name="background-light-blue-inputs-important"
            @on:select-option:change="changeProductType"
          />
        </b-col>
        <b-col cols="9">
          <label class="field-name-class">
            {{ $t('views.shop.form.product-name') }}
          </label>
          <d-text-field
            v-model="product.name"
            :rules="validation.name"
            class-name="background-light-blue-inputs"
            error-text="validation.text-input-three-characters"
            size="lg"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="3" v-if="product.type === 'digital'">
          <label class="field-name-class">
            {{ $t('views.shop.form.selected-pack') }}
          </label>
          <d-select-option
            :options="creditsPacks"
            v-model="choosenCreditsPack"
            class-name="background-light-blue-inputs-important"
            @on:select-option:change="changePaymentTokenPack"
          />
        </b-col>
        <b-col cols="3">
          <label class="field-name-class">
            {{ $t('views.shop.form.selling-price') }}
          </label>
          <price-text-field
            v-model="product.unitaryPrice"
            :disabled="priceDisabled"
            :rules="validation.unitaryPrice"
            size="lg"
            type="number"
            error-text="validation.sm-required"
            class-name="background-light-blue-inputs"
          />
        </b-col>
        <b-col cols="3">
          <label class="field-name-class">
            {{ $t('views.shop.form.selling-availability') }}
          </label>
          <d-select-option
            :options="enableProducts"
            v-model="product.enabled"
            v-value="product.enabled"
            :rules="validation.enabled"
            class-name="background-light-blue-inputs-important"
          />
        </b-col>
        <b-col v-if="product.type === 'walletRefill'">
          <label class="field-name-class">
            {{ $t('views.shop.form.wallet-selection') }}
          </label>
          <d-select-option
            v-if="!isBusy"
            v-model="product.walletRefill"
            :options="wallets"
            :rules="validation.walletRefill"
            error-text="validation.sm-required"
            class-name="background-light-blue-inputs-important"
            @on:select-option:change="changeWallet"
          />
          <b-spinner
            v-else
            variant="blue-light-spinner"
            type="grow"
          ></b-spinner>
        </b-col>
        <b-col
          v-if="product.type !== 'physical'"
          class="d-flex align-content-center align-items-center"
        >
          <div class="d-flex mt-4">
            <vs-checkbox
              v-model="product.giftable"
              color="primary"
              value="false"
            />
            <label class="simple-label-avenir mt-1">
              {{ $t('views.shop.giftable') }}
            </label>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <label class="field-name-class">
            {{ $t('views.shop.form.description') }}
          </label>
        </b-col>
        <b-col cols="12">
          <description-editor
            :default-content="product.description"
            @on:editor-content:update="onEditorContentUpdate"
          />
          <div v-if="invalidDescription" class="d-block invalid-feedback"> {{ invalidDescription }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label class="field-name-class">
            {{ $t('views.shop.form.short-description') }}
          </label>
        </b-col>
        <b-col cols="12">
          <b-textarea
            v-model="product.shortDescription"
            rows="5"
            size="sm"
            no-resize
            maxlength="100"
            minlength="2"
            class="background-light-blue-inputs"
            :state="count == 0 ? true : 'false'"
            @input.native="onShortDescriptionUpdate"
          />
          <div
            v-if="invalidShortDescription"
            class="d-block invalid-feedback"
          >
            {{ invalidShortDescription }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
import DescriptionEditor from "@custom/shop/DescriptionEditor";
import PriceTextField from "@custom/shop/PriceTextField";
import {getCreditPacks} from "@api/doinsport/services/payment-token-packs/payment-token-packs.api";
import {getAllWalletRefills} from "@api/doinsport/services/wallet-refills/wallet-refills.api";
import WalletRefill from "@/classes/doinsport/WalletRefill";

export default {
  components: {
    DescriptionEditor,
    PriceTextField
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    invalidDescription: {
      type: [Boolean, String],
    },
    invalidShortDescription: {
      type: [Boolean, String],
    },
  },
  data() {
    return {
      productTypes: [],
      wallets: [],
      enabledOptions: [],
      creditsPacks: [],
      priceDisabled: false,
      isBusy: false,
      choosenCreditsPack: null,
      selectedWallet: null,
      validation: require('@validation/entities/doinsport/Product.json')
    }
  },
  computed: {
    count() {
      if (this.product.shortDescription) {
        return 100 - this.product.shortDescription.length;
      } else {
        return 100;
      }
    },
    enableProducts() {
      return this.enabledOptions;
    },
    typeProducts() {
      return this.productTypes;
    }
  },
  methods: {
    changeWallet(wallet) {
      if (wallet) {
        const walletItem = this.wallets.find(el => el.value === wallet);

        this.product.unitaryPrice = walletItem.wallet.price;
      } else {
        this.product.unitaryPrice = null;
        this.product.walletRefill = null;
      }
    },
    onEditorContentUpdate(content) {
      this.product.description = content;
      this.$emit('on:reset-invalid-description')
    },
    onShortDescriptionUpdate(content) {
      this.$emit('on:reset-invalid-short-description')
    },
    changeProductType() {
      this.priceDisabled = this.product.type === 'digital';
      if (this.product.type === 'walletRefill') {
        this.loadWalletRefills();
      } else {
        this.product.walletRefill = null;
      }
    },
    initOptions () {
      this.enabledOptions = [
        {
          text: this.$t('views.shop.form.unavailable'),
          value: false,
        },
        {
          text: this.$t('views.shop.form.available'),
          value: true,
        }

      ];
      this.productTypes = [
        {
          text: this.$t('views.credits.physical-label'),
          value: 'physical'
        },
        {
          text: this.$t('views.credits.credits-label'),
          value: 'digital'
        },
        {
          text: this.$t('views.credits.wallet-refill-label'),
          value: 'walletRefill'
        }
      ];
    },
    changePaymentTokenPack() {
      this.product.paymentTokenPack = this.creditsPacks.find(el => el.value === this.choosenCreditsPack).pack;
      this.product.unitaryPrice = this.product.paymentTokenPack.price;
      this.product.setUnitaryPriceToEuros();
    },
    loadWalletRefills() {
      const nullableItem = {
        text: this.$t('general.actions.choose'),
        value: null
      };
      this.wallets = [nullableItem];
      this.isBusy = true;

      getAllWalletRefills(500, 1, '')
        .then(response => {
          for (const wallet of response.data['hydra:member']) {
            const serializedWallet = new WalletRefill(wallet, {serialize: true});
            const selectItem = {
              text: serializedWallet.name,
              value: wallet['@id'],
              wallet: serializedWallet,
            };

            this.wallets.push(selectItem);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    loadCreditsPacks() {
      getCreditPacks()
        .then((response) => {
          this.creditsPacks = [
            {
              text: this.$t('views.shop.form.selected-pack-not-selected'),
              value: null,
              disabled: true,
            }
          ];

          for (const creditPack of response.data['hydra:member']) {
            this.creditsPacks.push({
              text: creditPack.name,
              value: creditPack['@id'],
              pack: creditPack
            });
          }
        })
        .finally(() => {
          this.initOptions();
        })
      ;
    },
  },
  beforeCreate() {
    this.$vs.theme({
      primary: '#0b2772'
    })
  },
  created() {
    this.loadCreditsPacks();
    if (this.$route.params.id) {
      if (this.product.type !== 'walletRefill') {
        this.choosenCreditsPack = this.product.paymentTokenPack['@id'];
      }
    }
    this.changeProductType();
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/shop/_product-create-update-form.scss";

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}
</style>
