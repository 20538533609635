<template>
  <div v-if="product">
    <d-body-top-bar
        :title="$route.params.id ? $t('views.shop.edit-product') : $t('views.shop.new-product')"
    >
      <div slot="tree">
        <label
          class="pointer inactive-tree link-area ml-1"
          @click="$router.push({ name: 'shop'})"
        >
          {{ $t('views.shop.all-products') }}
        </label> /
        <label class="bg-doinsport-primary ml-1">
          {{ actualityLabel }}
        </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <div class="flex">
        <label class="balance-title">
          {{ $t('views.shop.general-informations') }}
        </label>
        <button @click="submit">{{ $route.params.id ? $t('views.shop.modify-product') : $t('views.shop.new-product') }}</button>
      </div>
      <b-row>
        <b-col cols="9">
          <create-update-product-form
            v-if="product !== null"
            :product="product"
            :invalid-description="invalidDescription"
            :invalid-short-description="invalidShortDescription"
            ref="productForm"
            @on:reset-invalid-description="invalidDescription = false"
            @on:reset-invalid-short-description="invalidShortDescription = false"
          />
        </b-col>
        <b-col cols="3">
          <categories
            :default-categories="product.categories"
            @on:product-category-change="onProductCategoryChange"
          />
          <preview
            :product="product"
            @on:product-files-change="onProductFilesChange"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Categories from "./Categories";
import Preview from "./Preview";
import CreateUpdateProductForm from "@form/shop/CreateUpdateProductForm";
import Product from "@/classes/doinsport/Product";
import {refine} from "@/utils/classes";

import {getProduct, postProduct, putProduct} from "@api/doinsport/services/products/products.api";

export default {
  components: {
    Categories,
    Preview,
    CreateUpdateProductForm,
  },
  data: () => ({
    product: null,
    invalidDescription: false,
    invalidShortDescription: false,
  }),
  computed: {
    actualityLabel() {
      return this.$route.params.id ? this.product.name : this.$t('views.shop.new-product');
    }
  },
  methods: {
    onProductCategoryChange(categories) {
      this.product.categories = categories
    },
    onProductFilesChange(files) {
      this.product.photos = files;
    },
    submit() {
      this.$refs.productForm.$refs.productObserver.validate()
        .then((valid) => {
          if (!this.product.description || this.product.description.trim().length < 1) {
            valid = false;
            this.invalidDescription = this.$t('views.shop.form.validation.empty-description')
          }

          if (!this.product.shortDescription || this.product.shortDescription.trim().length < 1) {
            valid = false;
            this.invalidShortDescription = this.$t('views.shop.form.validation.empty-short-description')
          }

          if (valid) {
            if (this.product.categories.length < 1) {
              this.$flash(null , this.$t('views.shop.form.validation.categories-not-selected'));
              return;
            }

            if (this.product.type === 'digital' && !this.product.paymentTokenPack) {
              this.$flash(null , this.$t('views.shop.form.validation.pack-not-selected'));
              return;
            }

            this.product.setUnitaryPriceToCents();
            const photo = this.product.photos[0];
            delete this.product.photos;
            refine(this.product);
            if (this.product.type === 'walletRefill') {
              this.product.type = 'digital';
            }
            if (this.product.id === null) {
              postProduct(this.product)
                .then((response) => {
                  if (photo) {
                    const payload = {
                      entry: 'product',
                      url: response.data['@id'],
                      target: 'postProductPhoto',
                      position: 0
                    };
                    this.$uploadFile(photo.file, payload).then(() => {
                      this.$router.push({name: 'shop'});
                    });;
                  }
                  this.$router.push({name: 'shop'});
                }).catch((err) => {
                  this.product.photos = [photo];
                  this.product.setUnitaryPriceToEuros();
                })
              ;
            } else {
              putProduct(this.product)
                .then((response) => {
                  if (photo && !photo.file['@id']) {
                    const payload = {
                      entry: 'product',
                      url: response.data['@id'],
                      target: 'postProductPhoto',
                      position: 0
                    };
                    this.$uploadFile(photo.file, payload).then(() => {
                      this.$router.push({name: 'shop'});
                    });
                  } else {
                    this.$router.push({name: 'shop'});
                  }
                })
                .catch((err) => {
                  this.product.photos = [photo];
                  this.product.setUnitaryPriceToEuros();
                })
              ;
            }
          }
        })
      ;
    },
    loadProduct() {
      if (this.$route.params.id) {
        getProduct(this.$route.params.id)
          .then((response) => {
            this.product = new Product(response.data, {serialize: true});
            if (response.data.walletRefill) {
              this.product.type = 'walletRefill';
            }
            this.product.setUnitaryPriceToEuros();

          })
      } else {
        this.product = new Product();
      }
    }
  },
  created() {
    this.loadProduct();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/shop/_product-create-update.scss";

/deep/ .ql-editor {
  max-height: unset;
  min-height: 200px;
}
</style>
