<template>
  <validation-provider
      v-slot="{ errors, ariaInput }"
      :name="$t(label)"
      :rules="rules"
  >
    <b-input-group size="md" :append="$currency">
      <b-form-input
          v-if="model === null || model === ''"
          v-model="model"
          v-bind="{ ...$attrs, ...ariaInput }"
          :type="type"
          :min="min"
          :max="max"
          :size="size"
          :value="value"
          :placeholder="$t(placeHolder)"
          :class="className"
          @click.native="$emit('on:text-field:click')"
          :autocomplete="autocomplete"
      />
      <b-form-input
          v-else
          v-model="model"
          v-bind="{ ...$attrs, ...ariaInput }"
          :type="type"
          :min="min"
          :placeholder="$t(placeHolder)"
          :class="className"
          :value="value"
          :max="max"
          :size="size"
          :state="errors.length === 0 && isValid"
          @change="$emit('on:text-field:change')"
          @click.native="$emit('on:text-field:click')"
          :autocomplete="autocomplete"
      />
    </b-input-group>
    <b-form-invalid-feedback
        v-if="rules && errors.length === 0"
        :state="rules.valid"
    >
      {{ rules.message }}
    </b-form-invalid-feedback>
    <div v-if="isValid">
      <b-form-invalid-feedback
          v-if="model !== ''"
          :state="errors.length === 0"
      >
        {{ $t(errorText) }}
      </b-form-invalid-feedback>
      <div v-else>
        <b-form-invalid-feedback
            v-if="model.length ==0"
            :state="errors.length === 0"
        >
          {{ $t(errorText) }}
        </b-form-invalid-feedback>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  props: {
    textFieldName: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    className: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: this,
    },
    max: {
      type: Number,
      default: this,
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    model: function () {
      if (isNotUndefinedAndNotNull(this.rules) && isNotUndefinedAndNotNull(this.rules.valid)) {
        if (!this.rules.valid) {
          this.rules.valid = true;
        }
      }
    }
  },
  computed: {
    isValid() {
      if (this.rules) {
        return this.rules.valid;
      }
      return true;
    },
    model: {
      get() {
        return this.value;
      },
      set(model) {
        this.$emit('input', model);
      }
    },
  },
  methods: {
    getLabel(ariaInput) {
      let label = this.$t(this.label);

      if (undefined !== ariaInput['aria-required'] && ariaInput['aria-required'] === 'true') {
        label += '*';
      }

      return label;
    },
  },
};
</script>
<style scoped>
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + -3.25rem);
}

/deep/ .form-control[readonly] {
  background-color: #EAF2F7;
  cursor: pointer;
}

</style>
