import {hydrate} from "@/utils/form";
import {toFloatFixed, toIntFixed} from "@/utils/string";

export default class Product {
  id = null;
  name = null;
  shortDescription = null;
  description = null;
  paymentTokenPack = null;
  type = 'physical';
  categories = [];
  unitaryPrice = null;
  walletRefill = null;
  enabled = true;
  giftable = false;
  photos = [];
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  deserialize(object) {
    hydrate(this, object);
  }

  setUnitaryPriceToCents() {
    this.unitaryPrice = toIntFixed(this.unitaryPrice);
  }

  setUnitaryPriceToEuros() {
    this.unitaryPrice = toFloatFixed(this.unitaryPrice);
  }
}
