import {hydrate} from "@/utils/form";

export default class WalletRefill {
  id = null;
  name = null;
  amount = null;
  price = null;
  enabled = true;
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
    }
  }

  serialize(object) {
    this.castToCurrency(object);
    hydrate(this, object);
  }

  deserialize(object) {
    hydrate(this, object);
    this.castToCents(object);
  }

  castToCents(object) {
    this.amount = parseInt(parseFloat(object.amount).toFixed(2) * 100);
    this.price = parseInt(parseFloat(object.price).toFixed(2) * 100);
  }

  castToCurrency(object) {
    object.amount = object.amount / 100;
    object.price = object.price / 100;
  }

}
