<template>
  <div>
    <b-card
      v-if="categories !== null"
      :title="$t('views.shop.form.product-categories')"
      header-tag="header"
      class="product-categories"
    >
      <i
        class="fa fa-plus-circle pointer"
        @click="openCreateProductCategoryModal()"
      />
      <template v-if="categories.length === 0">
        <b-card-text>{{ $t('views.shop.form.no-categories') }}</b-card-text>
      </template>

      <template v-else>
        <template v-for="(category, idx) in categories">
          <div class="group-checkbox" :key="idx">
            <input
              type="checkbox"
              name="categories"
              :value="category"
              :id="'category-' + idx"
              v-model="selectedCategories"
            >
            <label :for="'category-' + idx">{{ category.name }}</label>
            <i class="ml-auto fa fa-pencil pointer" @click="editCategory(category)"></i>
            <!-- <i class="ml-2 fa fa-trash pointer" @click="deleteCategory(category.id)"></i> -->
          </div>
        </template>
      </template>
    </b-card>

    <create-update-product-category-modal
      modal-id="product-category-modal-id"
      ref="createUpdateProductCategoryModal"
      :product-category="productCategory"
      @on:product-category-created="loadCategories"
    />
  </div>
</template>

<script>
import ProductCategory from "@/classes/doinsport/ProductCategory";
import {getAllCategories, deleteProductCategory} from "@api/doinsport/services/products/products.api";
import CreateUpdateProductCategoryModal from "@custom/shop/CreateUpdateProductCategoryModal";

export default {
  name: "Categories",
  components: {
    CreateUpdateProductCategoryModal,
  },
  props: {
    defaultCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedCategories: [],
      categories: null,
      productCategory: new ProductCategory(),
    }
  },
  methods: {
    loadCategories() {
      getAllCategories()
        .then((response) => {
          this.categories = response.data['hydra:member'];
        })
        .catch((error) => {
          this.categories = [];
        })
      ;
    },
    editCategory(category) {
      this.productCategory = category;
      this.$nextTick(() => {
        this.$refs.createUpdateProductCategoryModal.show();
      })
    },
    openCreateProductCategoryModal() {
      this.productCategory = new ProductCategory();

      this.$nextTick(
        () => {
          this.$refs.createUpdateProductCategoryModal.show();
        })
      ;
    },
    deleteCategory(id) {
      deleteProductCategory(id);
      this.loadCategories();
    },
  },
  mounted() {
    this.loadCategories();
    this.selectedCategories = this.defaultCategories;
  },
  watch: {
    selectedCategories() {
      this.$emit('on:product-category-change', this.selectedCategories)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/shop/_product-categories.scss";
</style>
