import {clubId, _delete, _get, _post, _put} from "@api/doinsport/services/httpService";

const URL = '/clubs/wallet-refills';

export const getAllWalletRefills = (itemsPerPage, page, filter, enabled) => {
  let endPoint = URL +
    '?club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + filter
  ;

  if ("undefined" === typeof enabled) {
    endPoint += '&enabled=true';
  }
  else if ("undefined" !== typeof enabled && !enabled) {
    endPoint += '&enabled=false';
  }
  else if ("undefined" !== typeof enabled && enabled) {
    endPoint += '&enabled=true';
  }

  return _get(endPoint);
}

export const getWallet = (id) => _get(URL + '/' + id);

export const deleteWallet = (id) => _delete(URL + '/' + id);

export const postWallet = (data) => _post(URL, data);

export const putWallet = (data) => _put(URL + '/' + data.id, data);
