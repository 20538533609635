<template>
  <b-card>
    <div>
      <label class="balance-title">
        {{ $t('views.shop.form.preview') }}
      </label>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="image-container mt-2"
        v-for="(photo, idx) in files"
        :key="idx"
      >
        <img
          :src="photo.url"
          class="object-fit-img"
          height="100%"
          width="100%"
          alt
        />
        <div class="product-hover">
          <button @click="resetPhoto(idx)" type="button">
            <i class="icon-trash"></i>
          </button>
        </div>
      </div>
      <img
        v-if="files.length < 1"
        class="pointer mt-4"
        height="95"
        width="95"
        src="@/assets/plus.svg"
        @click="uploadPhoto"
        alt
      />
    </div>
  </b-card>
</template>
<script>
import {deleteProductPhoto} from "@api/doinsport/services/products/products.api";

export default {
  data: () => ({
    files: []
  }),
  props: {
    product: {
      type: Object,
      default: this,
    }
  },
  methods: {
    uploadPhoto() {
      this.$upload(this.uploaded, {entry: 'product', url: null, target: ''});
    },
    uploaded(file) {
      const url = URL.createObjectURL(file);
      this.files.push({
        file: file,
        url: url
      })
    },
    resetPhoto(id) {
      const photo = this.files[id];
      if (photo.file['@id']) {
        deleteProductPhoto(photo.file.id)
          .then(() => {
            this.files.splice(id, 1);
          })
        ;
      } else {
        this.files.splice(id, 1);
      }
    },
  },
  watch: {
    files() {
      this.$emit('on:product-files-change', this.files)
    }
  },
  created() {
    if (this.product.photos) {
      this.product.photos.forEach((photo) => {
        this.files.push({
          file: photo,
          url: this.$filePath(photo.contentUrl)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/shop/_product-preview.scss";

</style>


